<template>
  <div class="container">
    <div class="column form-wrap is-4">
      <div class="box" id="login">
        <div class="cst-logo m-4 has-text-centered">
          <img
            src="../../assets/logo.png"
            style="height: 36px"
            alt="cst-logo"
          />
        </div>
        <b-field label="">
          <b-input
            v-model="email"
            placeholder="Email"
            :readonly="isLoading"
          ></b-input>
        </b-field>
        <b-field>
          <b-input
            type="password"
            v-model="password"
            placeholder="Password"
            password-reveal
            :readonly="isLoading"
          >
          </b-input>
        </b-field>
        <div class="mt-1 has-text-left">
          <a @click.prevent="forgotPassword">Forgot password?</a>
        </div>
        <div class="field mt-5">
          <div class="control">
            <b-button
              type="is-primary is-fullwidth mb-3"
              @click="submitForm"
              :loading="isLoading"
            >
              Login</b-button
            >
            <b-button
              type="is-primary is-light is-fullwidth mb-3"
              @click="signUp"
              >Create account</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      url: "/auth/token/login/",
      error: false,
      errorMessage: "",
      redirect: this.$route.query.redirect,
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    isLoading() {
      return this.$store.getters.isLoading;
    },
  },
  methods: {
    async performLogin() {
      try {
        const response = await axios.post(this.url, {
          email: this.email,
          password: this.password,
        });
        this.$store.dispatch("logIn", response.data.auth_token);
        this.$store.dispatch("employers/fetchEmployers");
      } catch (error) {
        this.error = true;
        this.errorMessage = "Unable to log in with provided credentials.";
      }
    },
    formValid() {
      return this.email.length > 0 && this.password.length > 0;
    },
    submitForm() {
      if (this.formValid()) {
        // setLoading
        this.$store.dispatch("setLoading", true);

        setTimeout(() => {
          this.error = false;
          this.errorMessage = "";
          this.performLogin().then(() => {
            // unset loading
            this.$store.dispatch("setLoading", false);
            if (this.error) {
              this.$buefy.toast.open({
                message: this.errorMessage,
                type: "is-danger",
              });
            } else {
              this.$router.push(this.redirect || { name: "EmployerDashboard" });
            }
          });
        }, 1000);
      } else {
        this.error = true;
        this.errorMessage = "Please fill in all the required fields.";
        this.$buefy.toast.open({
          message: this.errorMessage,
          type: "is-danger",
        });
      }
    },
    signUp() {
      this.$router.push({ name: "UserSignup" });
    },
    forgotPassword() {
      this.$router.push({ name: "UserResetPassword" });
    },
  },
};
</script>

<style scoped>
.form-wrap {
  margin: 0 auto;
  margin-top: 10%;
}
</style>
